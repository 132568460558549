import type {
  ContestPositionMap,
  ConferenceContest,
  ConferenceOption,
  ContestContent,
  OptionContent, 
  HandRaiseOrchestrator,
} from '@/types';

const formatContest = (contest: ConferenceContest): ContestContent & { optionsCount: number } => {
  const options: ConferenceOption[] = contest?.options?.filter((el: ConferenceOption) => el.parent === null);

  const formatted: ContestContent & { optionsCount: number } = {
    reference: contest.reference,
    title: contest.title,
    options: options as OptionContent[],
    optionsCount: contest.optionsCount,
    customRulesets: contest.customRulesets,
    disregardVoterWeight: contest.disregardWeights,
    markingType: {
      votesAllowedPerOption: contest.votesAllowedPerOption,
      blankSubmission: contest.blankOption,
      minMarks: contest.minimumVotes,
      maxMarks: contest.maximumVotes,
      maxPiles: contest.maxPiles,
      voteVariation: contest.ruleSet,
      encoding: {
        codeSize: 1,
        maxSize: 1,
        cryptogramCount: 1
      }
    },
    resultType: {
      name: contest.resultMethod
    },
  };

  if (contest.subtitle) formatted.subtitle = contest.subtitle;
  if (contest.question) formatted.question = contest.question;
  if (contest.description) formatted.description = contest.description;
  if (contest.collapsable) formatted.collapsable = contest.collapsable;
  if (contest.collapseDefault) formatted.collapseDefault = contest.collapseDefault;
  if (contest.searchForm) formatted.searchForm = contest.searchForm;
  if (contest.randomizeOptions) formatted.randomizeOptions = contest.randomizeOptions;
  if (contest.blankOptionColor) formatted.blankOptionColor = contest.blankOptionColor;

  return formatted;
}


const orderContests = (contestsSelection: ConferenceContest[], contestPositions: ContestPositionMap): ConferenceContest[] => {
  return contestsSelection.sort((a, b) =>
    contestPositions[a.reference] - contestPositions[b.reference]
  );
}

const orderResults = (contestsSelection: HandRaiseOrchestrator[], contestPositions: ContestPositionMap): HandRaiseOrchestrator[] => {
  return contestsSelection.sort((a, b) =>
    contestPositions[a.contestReference] - contestPositions[b.contestReference]
  );
}


const flattenOptions = (tree: ConferenceOption[]): ConferenceOption[] => {
  return tree.flatMap((option: ConferenceOption) => {
    if (option.children) return [option, ...flattenOptions(option.children)];
    return [option];
  });
}

export { formatContest, orderContests, flattenOptions, orderResults };
